<template>
  <div>
    <div class="main-dashboard">
      <div v-for="item in dashboard" :key="item.title" class="flex" @click="gotoPage(item.route)">
        <link-card :item="item" class="card-style" />
      </div>
    </div>
  </div>
</template>

<script>
import LinkCard from "./LinkCard.vue";
import { clearLocalStorage } from "../../../helper/localStorage.js";
import { dashboardList, operationUserMenuList } from "./dashboardList";
import { mapActions } from "vuex";

export default {
  components: { LinkCard },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
  data() {
    return {
      dashboard: [],
    };
  },
  methods: {
    ...mapActions("admin", ["checkLogin", "getAdminUser"]),
    async getUser() {
      this.$vs.loading();
      await this.getAdminUser(this.user._id).then((result) => {
        this.$vs.loading.close();
        const user = result.data.data;

        if (!user.permissions || !user.permissions.disbursePaymentRequests) {
          const disburseIndex = dashboardList.findIndex(item => item.title == "Disbursements");
          dashboardList.splice(disburseIndex, 1);
        }

        this.dashboard = user.userType === "operationAdmin" ? operationUserMenuList : dashboardList;
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    gotoPage(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  created() {
    this.checkAdminLogin();

    if (!this.user) {
      clearLocalStorage();
      this.$router.push("/login").catch(() => {});
    }

    this.getUser();
  },
};
</script>

